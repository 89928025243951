.c-list-variable-group-statistics {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  transition: background-color ease 1s, color ease 1s;

  &__header {
    flex-shrink: 0;
    padding: 1em;
    text-align: center;
  }

  &__title {
    margin-bottom: 0.5em;
  }

  &__content {
    flex-grow: 5;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    table {
      width: 100%;

      th,
      td {
        text-align: left;
        padding: 1em;
      }
    }
  }

  &__footer {
    flex-shrink: 0;
    padding: 1em;
    text-align: center;
  }
}
