.c-chart-variable-statistics {
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;

  &__header {
    flex-shrink: 0;
    padding: 1em;
    text-align: center;
  }

  &__title {
    margin-bottom: 0.5em;
  }

  &__content {
    flex-grow: 5;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__victory {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  &__footer {
    flex-shrink: 0;
    padding: 1em;
    text-align: center;
  }
}
