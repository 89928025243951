.debug-console {
  display: flex;
  flex-direction: column;

  &__header {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    flex-grow: 5;
    border: 1px solid var(--color-list-border);
    overflow: auto;
  }

  &__list {
    display: grid;
    grid-template-columns: 1.5em min-content auto;
    font-family: monospace;
    font-size: 0.9em;
  }
}

.debug-item {
  $block: &;

  &__date,
  &__icon,
  &__message {
    padding: 0.25em;
    border-bottom: 1px solid var(--color-list-item-border);

    &#{$block}--error {
      background: var(--color-state-error-bg);
    }

    &#{$block}--warning {
      background: var(--color-state-warning-bg);
    }
  }

  &__icon {
    padding-right: 0;

    .rs-icon {
      font-size: 0.75em;
    }

    &#{$block}--error {
      .rs-icon {
        color: var(--color-state-error-text);
      }
    }

    &#{$block}--warning {
      .rs-icon {
        color: var(--color-state-warning-text);
      }
    }

    &#{$block}--info {
      .rs-icon-check-circle {
        color: var(--color-state-success-text);
      }
    }
  }

  &__date {
    white-space: nowrap;
    font-size: 0.8em;
    padding-top: 0.4em;
    color: var(--color-text-light);
  }

  &__message {
    &#{$block}--error {
      color: var(--color-state-error-text);
    }

    p + p {
      margin: 0;
    }
  }

  &__code {
    max-height: 4em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}
